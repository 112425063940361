define("discourse/plugins/discourse-adplugin/discourse/components/ad-slot", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse/plugins/discourse-adplugin/discourse/helpers/slot-position", "@ember/template-factory"], function (_exports, _component, _object, _service, _utils, _decorators, _adComponent, _slotPosition, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slotContenders = slotContenders;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.adComponents as |adComponent|}}
    {{! Trick to force full destroy/re-render of component when route changes }}
    {{#each (array this.router.currentRoute)}}
      {{component
        adComponent
        placement=this.placement
        category=this.category
        postNumber=this.postNumber
        indexNumber=this.indexNumber
        tagName=this.childTagName
      }}
    {{/each}}
  {{/each}}
  */
  {
    "id": "D5z0KdoN",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"adComponents\"]]],null]],null],null,[[[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[30,0,[\"router\",\"currentRoute\"]]],null]],null]],null],null,[[[1,\"    \"],[46,[30,1],null,[[\"placement\",\"category\",\"postNumber\",\"indexNumber\",\"tagName\"],[[30,0,[\"placement\"]],[30,0,[\"category\"]],[30,0,[\"postNumber\"]],[30,0,[\"indexNumber\"]],[30,0,[\"childTagName\"]]]],null],[1,\"\\n\"]],[]],null]],[1]],null]],[\"adComponent\"],false,[\"each\",\"-track-array\",\"array\",\"component\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/ad-slot.hbs",
    "isStrictMode": false
  });
  const adConfig = _object.default.create({
    "google-adsense": {
      settingPrefix: "adsense",
      // settings follow naming convention
      enabledSetting: "adsense_publisher_code",
      nthPost: "adsense_nth_post_code"
    },
    "google-dfp-ad": {
      settingPrefix: "dfp",
      // settings follow naming convention
      enabledSetting: "dfp_publisher_id",
      nthPost: "dfp_nth_post_code"
    },
    "amazon-product-links": {
      settingPrefix: "amazon",
      enabledSetting: false,
      nthPost: "amazon_nth_post_code",
      desktop: {
        "topic-list-top": "amazon_topic_list_top_src_code",
        "post-bottom": "amazon_post_bottom_src_code",
        "topic-above-post-stream": "amazon_topic_above_post_stream_src_code",
        "topic-above-suggested": "amazon_topic_above_suggested_src_code"
      },
      mobile: {
        "topic-list-top": "amazon_mobile_topic_list_top_src_code",
        "post-bottom": "amazon_mobile_post_bottom_src_code",
        "topic-above-post-stream": "amazon_mobile_topic_above_post_stream_src_code",
        "topic-above-suggested": "amazon_mobile_topic_above_suggested_src_code"
      }
    },
    "carbonads-ad": {
      settingPrefix: "carbonads",
      enabledSetting: "carbonads_serve_id",
      desktop: {
        "topic-list-top": "carbonads_topic_list_top_enabled",
        "post-bottom": false,
        "topic-above-post-stream": "carbonads_above_post_stream_enabled",
        "topic-above-suggested": false
      }
    },
    "adbutler-ad": {
      settingPrefix: "adbutler",
      enabledSetting: "adbutler_publisher_id",
      desktop: {
        "topic-list-top": "adbutler_topic_list_top_zone_id",
        "post-bottom": "adbutler_post_bottom_zone_id",
        "topic-above-post-stream": "adbutler_topic_above_post_stream_zone_id",
        "topic-above-suggested": "adbutler_topic_above_suggested_zone_id"
      },
      mobile: {
        "topic-list-top": "adbutler_mobile_topic_list_top_zone_id",
        "post-bottom": "adbutler_mobile_post_bottom_zone_id",
        "topic-above-post-stream": "adbutler_mobile_topic_above_post_stream_zone_id",
        "topic-above-suggested": "adbutler_mobile_topic_above_suggested_zone_id"
      }
    }
  });
  const displayCounts = {
    houseAds: 0,
    allAds: 0
  };
  function _isNetworkAvailable(siteSettings, enabledNetworkSettingName) {
    // False means there's no setting to enable or disable this ad network.
    // Assume it's always enabled.
    if (enabledNetworkSettingName === false) {
      return true;
    } else {
      return enabledNetworkSettingName && !(0, _utils.isBlank)(siteSettings[enabledNetworkSettingName]);
    }
  }
  function _shouldPlaceAdInSlot(siteSettings, currentPostNumber, positionToPlace) {
    return !currentPostNumber || !positionToPlace || (0, _slotPosition.isNthPost)(parseInt(siteSettings[positionToPlace], 10), currentPostNumber);
  }
  function slotContenders(site, siteSettings, placement, indexNumber, postNumber) {
    let types = [];
    const houseAds = site.get("house_creatives"),
      placeUnderscored = placement.replace(/-/g, "_");
    if (houseAds && houseAds.settings) {
      const adsForSlot = houseAds.settings[placeUnderscored];
      const adAvailable = Object.keys(houseAds.creatives).length > 0 && !(0, _utils.isBlank)(adsForSlot);

      // postNumber and indexNumber are both null for topic-list-top, topic-above-post-stream,
      // and topic-above-suggested placements. Assume we want to place an ad outside the topic list.
      const notPlacingBetweenTopics = !postNumber && !indexNumber;
      const canBePlacedInBetweenTopics = placeUnderscored === "topic_list_between" && (0, _slotPosition.isNthTopicListItem)(parseInt(houseAds.settings.after_nth_topic, 10), indexNumber);
      if (adAvailable && (notPlacingBetweenTopics || canBePlacedInBetweenTopics || (0, _slotPosition.isNthPost)(parseInt(houseAds.settings.after_nth_post, 10), postNumber))) {
        types.push("house-ad");
      }
    }
    Object.keys(adConfig).forEach(adNetwork => {
      const config = adConfig[adNetwork];
      let settingNames = null,
        name;
      if (_isNetworkAvailable(siteSettings, config.enabledSetting) && _shouldPlaceAdInSlot(siteSettings, postNumber, config.nthPost)) {
        if (site.mobileView) {
          settingNames = config.mobile || config.desktop;
        } else {
          settingNames = config.desktop;
        }
        if (settingNames) {
          name = settingNames[placement];
        }
        if (name === undefined) {
          // follows naming convention: prefix_(mobile_)_{placement}_code
          name = `${config.settingPrefix}_${site.mobileView ? "mobile_" : ""}${placeUnderscored}_code`;
        }
        if (name !== false && siteSettings[name] !== false && !(0, _utils.isBlank)(siteSettings[name])) {
          types.push(adNetwork);
        }
      }
    });
    return types;
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _adComponent.default.extend(dt7948.p({
    router: (0, _service.service)(),
    tagName: "",
    availableAdTypes(placement, postNumber, indexNumber) {
      return slotContenders(this.site, this.siteSettings, placement, indexNumber, postNumber);
    },
    adComponents(placement, availableAdTypes) {
      if (!availableAdTypes.includes("house-ad") || availableAdTypes.length === 1) {
        // Current behaviour is to allow multiple ads from different networks
        // to show in the same place. We could change this to choose one somehow.
        return availableAdTypes;
      }
      const houseAds = this.site.get("house_creatives");
      let houseAdsSkipped = false;
      if (houseAds.settings.house_ads_frequency === 100) {
        // house always wins
        return ["house-ad"];
      } else if (houseAds.settings.house_ads_frequency > 0) {
        // show house ads the given percent of the time
        if (displayCounts.allAds === 0 || 100 * displayCounts.houseAds / displayCounts.allAds < houseAds.settings.house_ads_frequency) {
          displayCounts.houseAds += 1;
          displayCounts.allAds += 1;
          return ["house-ad"];
        } else {
          houseAdsSkipped = true;
        }
      }
      const networkNames = availableAdTypes.filter(x => x !== "house-ad");
      if (houseAdsSkipped) {
        displayCounts.allAds += networkNames.length;
      }
      return networkNames;
    }
  }, [["method", "availableAdTypes", [(0, _decorators.default)("placement", "postNumber", "indexNumber")]], ["method", "adComponents", [(0, _decorators.default)("placement", "availableAdTypes", "router.currentRoute")]]])));
});