define("discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad", ["exports", "@ember/component", "@ember/template", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "@ember/template-factory"], function (_exports, _component, _template, _decorators, _adComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAd}}
    {{! template-lint-disable no-forbidden-elements }}
    <script src={{this.url}} id="_carbonads_js" async type="text/javascript">
    </script>
  {{/if}}
  */
  {
    "id": "UlVRqTUN",
    "block": "[[[41,[30,0,[\"showAd\"]],[[[1,\"  \"],[10,\"script\"],[15,\"src\",[30,0,[\"url\"]]],[14,1,\"_carbonads_js\"],[14,\"async\",\"\"],[14,4,\"text/javascript\"],[12],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _adComponent.default.extend(dt7948.p({
    serve_id: null,
    placement: null,
    init() {
      this.set("serve_id", this.siteSettings.carbonads_serve_id);
      this.set("placement", this.siteSettings.carbonads_placement);
      this._super();
    },
    url(serveId, placement) {
      return (0, _template.htmlSafe)(`//cdn.carbonads.com/carbon.js?serve=${serveId}&placement=${placement}`);
    },
    showCarbonAds() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_carbon_ads;
    },
    showAd(placement, serveId, showCarbonAds, showToGroups, showOnCurrentPage) {
      return placement && serveId && showCarbonAds && showToGroups && showOnCurrentPage;
    }
  }, [["method", "url", [(0, _decorators.default)("serve_id", "placement")]], ["method", "showCarbonAds", [_decorators.default]], ["method", "showAd", [(0, _decorators.default)("placement", "serve_id", "showCarbonAds", "showToGroups", "showOnCurrentPage")]]])));
});